'use client'
import { useSearchType } from '@/api/hooks'
import { SearchType } from '@/app/(site)/search/types'
import { ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
type SearchContextProps = {
    isTransitioning: boolean
    setTransitioning: React.Dispatch<SetStateAction<boolean>>
}

export const SearchTypeContext = createContext({
    isTransitioning: false,
} as SearchContextProps)

const SearchTypeContextManager = ({ children }: { children: ReactNode }) => {
    const searchParamType = useSearchType() || 'gifs'

    const [searchType, setSearchType] = useState<SearchType>(searchParamType || 'gifs')

    // set when a toggle is clicked to let the animation happen
    const [isTransitioning, setTransitioning] = useState(false)

    const value = useMemo(
        () => ({ searchType, setSearchType, isTransitioning, setTransitioning }),
        [searchType, isTransitioning]
    )
    useEffect(() => {
        if (searchParamType !== searchType) {
            setSearchType(searchParamType)
            setTransitioning(false)
        }
    }, [searchParamType, searchType, setSearchType])
    return <SearchTypeContext.Provider value={value}>{children}</SearchTypeContext.Provider>
}

export default SearchTypeContextManager
