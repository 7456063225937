'use client'
import { getUserOnClient } from '@/util/user'
import { ReactNode, useEffect, useState } from 'react'
import { SWRConfig } from 'swr'
import { ILoggedInUser } from 'types'
import { PortalMessageContextManager } from 'ui/src/components/message/portal-message/context'
import EditManager from './edit-manager'
import { FavoritesManager } from './favorites-manager'
import FlashMessage from './flash-message'
import SearchTypeContextManager from './search-type'
import SearchbarContextManager from './searchbar-manager'
import UAParserContext, { UAParserProps } from './ua-parser'
import UserContext, { UserContextProps } from './user-context'

type Props = { children: ReactNode } & UserContextProps & UAParserProps
const ContextManager = ({ children, user: userSSR, deviceType, isBot }: Props) => {
    // user should come from ssr if there is a lot of page layout that depends on it
    // otherwise fetch clientside
    const [user, setUser] = useState<ILoggedInUser | undefined | null>(userSSR)
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await getUserOnClient()
                // set the user as null if there is none to flag that we have fetched
                setUser(user || null)
            } catch (error) {}
        }
        if (user === undefined) {
            fetchUser()
        }
    }, [user])
    return (
        <UAParserContext.Provider value={{ deviceType, isBot }}>
            <UserContext.Provider
                value={{
                    user,
                    hasFetchedUser: user !== undefined,
                }}
            >
                <SWRConfig
                    value={{
                        fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
                    }}
                >
                    <SearchTypeContextManager>
                        <PortalMessageContextManager>
                            <EditManager>
                                <FavoritesManager>
                                    <SearchbarContextManager>
                                        <FlashMessage>{children}</FlashMessage>
                                    </SearchbarContextManager>
                                </FavoritesManager>
                            </EditManager>
                        </PortalMessageContextManager>
                    </SearchTypeContextManager>
                </SWRConfig>
            </UserContext.Provider>
        </UAParserContext.Provider>
    )
}

export default ContextManager
